import { Box, TextField,Typography } from "@mui/material";
import "./TextAreaField.scss";
import React from "react"
export const TextAreaField = (props: any) => {
  return (
    <Box className="textAreaGroup">
    {props?.textAreaLabel && (
      <Typography className="textAreaLabel">
        {props?.textAreaLabel}
        {props?.required && <span style={{ color: "#F10000" }}>{"  "}*</span>}
      </Typography>
    )}
    <TextField
      variant="standard" className="textAreaField" {...props}
      hiddenLabel 
      multiline
    />
    </Box>

  );
};
