import { Box, Link, TextField, Typography } from "@mui/material";
import "./HowItsWorks.scss";
import howItsIcon1 from "../../../statics/svgs/howItsIcon1.svg";
import howItsIcon2 from "../../../statics/svgs/howItsIcon2.svg";
import howItsIcon3 from "../../../statics/svgs/howItsIcon3.svg";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";

export const HowItsWorks = () => {
  return (
    <Box className="howItsWorks">
      <Box className="containerBox">
      <SectionHeading
          heading="How it Works?"
          subHeading="100x faster segmentation & 90% accurate churn Prediction powered by AI"
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="howItsWorkBox">
            <Box className="imgBox"><img src={howItsIcon1} alt="" /></Box>
            <Typography variant="h2">Micro-Segmentation</Typography>
            <Typography>
              AI-Powered autonomous Customer Groups generation 
            </Typography>
          </Box>
          <Box className="howItsWorkBox">
            <Box className="imgBox"><img src={howItsIcon2} alt="" /></Box> 
            <Typography variant="h2">Campaign Recommendation</Typography>
            <Typography>
            Auto-piloted marketing campaigns on the go
            </Typography>
          </Box>
          <Box className="howItsWorkBox">
            <Box className="imgBox"><img src={howItsIcon3} alt="" /></Box>  
            <Typography variant="h2">Profile building</Typography>
            <Typography>
            Deep monitoring of consumer behaviours so you can know them better
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
