import "./ContactUs.scss";
import React from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Modal,
} from "@mui/material";
import { TextAreaField } from "../common/inputfield/TextAreaField";
import { InputField } from "../common/inputfield/InputField";
import arrow from "../../statics/svgs/arrow.svg";
import checkIcon from "../../statics/images/checkIcon.gif";
import { useNavigate } from "react-router-dom";

interface IProps {
  pathName?: string;
  setOpenLetsConnectFromCdp?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const ContactUs = (props: IProps) => {
  const navigate = useNavigate();
  const [role, setRole] = React.useState("");
  const [name, setName]: any = React.useState("");
  const [email, setEmail]: any = React.useState("");
  const [message, setMessage]: any = React.useState("");
  const [phone, setPhone]: any = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleChangeRole = (event: any) => {
    setRole(event.target.value as string);
  };

  const handleClose = () => setOpen(false);
  const sendmail = async (e: any) => {
    e.preventDefault();
    const apiUrl = `https://prod-loq8-checkout.warpspd.ai/ingenia/email`;
    const res = await fetch(`${apiUrl}`, {
      body: JSON.stringify({
        name: name,
        email: email,
        mobile: phone,
        role: role,
        message: message,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    if (res.status == 200) {
      setName("");
      setEmail("");
      setMessage("");
      setPhone("");
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        if (props?.pathName) {
          props.setOpenLetsConnectFromCdp(false);
          navigate(props?.pathName);
        } else {
          navigate("/");
        }
      }, 2000);
    }
    const { error } = await res.json();
    if (error) {
      console.log(error);
      return;
    }
  };

  const isFormValid = () => {
    if (!name || name.length === 0) {
      return false;
    }

    if (!email || email.length === 0) {
      return false;
    }

    if (!phone || phone.length === 0) {
      return false;
    }

    if (!role || role.length === 0) {
      return false;
    }

    return true;
  };

  return (
    <Box className="contactUs" id="five">
      <Box
        className="contactUsContainer"
        width="100%"
        maxWidth="1420px"
        padding="0 40px"
        margin="0 auto"
      >
        <Box className="pageTitle">
          <Typography variant="h2">Interested in learning more? </Typography>
          <Typography>Get in touch with us</Typography>
        </Box>

        <form onSubmit={sendmail}>
          <Box className="formArea">
            <Box className="inputFieldBox">
              <InputField
                inputLabel="Name"
                required="please enter code"
                placeholder="Enter Name"
                name="firstName"
                type="text"
                value={name}
                onChange={(event: any) => {
                  setName(event.target.value);
                }}
              />
            </Box>

            <Box className="inputFieldBox">
              <Typography className="dropDownLabel">
                Role <span>*</span>
              </Typography>
              <FormControl fullWidth className="customDropDown">
                <Select
                  placeholder={"Select option"}
                  variant="standard"
                  value={role}
                  required
                  displayEmpty
                  renderValue={role !== "" ? undefined : () => "Select option"}
                  onChange={handleChangeRole}
                >
                  <MenuItem value={"VC"}>VC</MenuItem>
                  <MenuItem value={"Founder"}>Founder</MenuItem>
                  <MenuItem value={"Business Owner"}>Business Owner</MenuItem>
                  <MenuItem value={"CEO"}>CEO</MenuItem>
                  <MenuItem value={"Board Member"}>Board Member</MenuItem>
                  <MenuItem value={"Executive"}>Executive</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="inputFieldBox">
              <InputField
                inputLabel="Email Address"
                required
                placeholder="Enter Email"
                name="name"
                type={email}
                value={email}
                onChange={(event: any) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Box className="inputFieldBox">
              <InputField
                inputLabel="Phone"
                required
                placeholder="Phone"
                name="number"
                type="number"
                value={phone}
                onChange={(event: any) => {
                  setPhone(event.target.value);
                }}
              />
            </Box>
            <Box className="textAreaFieldBox">
              <TextAreaField
                textAreaLabel="Message"
                // required
                placeholder="Message"
                name="message"
                type="text"
                value={message}
                onChange={(event: any) => {
                  setMessage(event.target.value);
                }}
              />
            </Box>
          </Box>
          <Box className="primaryBtn">
            <Button variant="contained" type="submit" disabled={!isFormValid()}>
              Submit Request
              <img className="arrow" src={arrow} width="17px" height="18px" />
            </Button>
          </Box>
        </form>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="customPopUp">
            <img src={checkIcon} />
            <Typography variant="h2" component="h2">
              Your response has been recorded
            </Typography>
            <Typography> We will shortly get in touch with you</Typography>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};
export default ContactUs;
