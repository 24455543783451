import { Box, Typography } from "@mui/material"
import "./Footer.scss" 

export const Footer = (props: any) => {
  return (
    <Box className="footerBox"> 
      <Typography>Copyright © Ingenia 2023, All rights reserved.</Typography>
    </Box>
  )
}
 
