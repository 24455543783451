import "./wrapper.scss"
import { Header } from "../header/Header"
import Proposition from "../../proposition/Proposition"
import OurValue from "../../ourValue/OurValue"
import OurTeam from "../../ourTeam/OurTeam"
import LetTalk from "../../LetTalk/LetTalk"
import ContactUs from "../../contactUs/ContactUs"

const Wrapper = (props: any) => {
  return (
    <>
      <Header/>
      <Proposition/>
      <OurValue />
      <OurTeam />
      <LetTalk />
      <ContactUs />
    </>
  )
}

export default Wrapper
