import { Box, Link, TextField, Typography } from "@mui/material";
import "./RewardPoints.scss";   
import rewardPoints1 from "../../../statics/svgs/rewardPoints1.svg";
import rewardPoints2 from "../../../statics/svgs/rewardPoints2.svg";
import rewardPoints3 from "../../../statics/svgs/rewardPoints3.svg";  
export const RewardPoints = () => {
  return (
    <Box className="rewardPoints">
      <Box className="containerBox"> 
        <Box className="rowBox"> 
          <Box className="rewardPointsBox">
            <img src={rewardPoints1} alt="" />
            <Typography variant="h2">What can you get for your reward points</Typography>
          </Box> 
          <Box className="rewardPointsBox">
            <img src={rewardPoints2} alt="" />
            <Typography variant="h2">Reward <br /> details</Typography>
          </Box>
          <Box className="rewardPointsBox">
            <img src={rewardPoints3} alt="" />
            <Typography variant="h2">Your point and tier <br />summary</Typography>
          </Box> 
        </Box>
        <Box className="listRow">
          <Box className="listBox">
            <Typography variant="h3">Consumers </Typography>
            <ul>
              <li>Accrue and redeem points</li>
              <li>Upgrade rewards with bigger benefits as they earn more points</li>
              <li>Access transactions and points history</li>
              <li>App-less Mobile experience on their own phones</li>
            </ul>
          </Box>
          <Box className="listBox">
          <Typography variant="h3">Dispensaries and Brands </Typography>
          <ul>
              <li>configure points earning rules</li>
              <li>access to data analytics for loyal customers</li>
              <li>access to loyalty dashboard</li>
              <li>configure loyalty giveaways</li>
              <li>configure offers & deals</li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
