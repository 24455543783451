import { Box, Typography, Link } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import "./Blog.scss";
import { Header } from "../common/header/Header";
import blogImg from "../../statics/images/blog.jpg";
import arrow from "../../statics/svgs/arrow.svg";

const Blog = () => {
  return (
    <Box className="blogPage">
      <Header />
      <Box className="blog">
        <Box className="blogRow">
          <Card sx={{maxWidth: 420, width: "calc(100%)", paddingBottom: "20px" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={blogImg}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Data Security White Paper
              </Typography> 
            </CardContent>
            <CardActions sx={{padding: "0px 15px"}}>
              <Link className="primaryBtn" href="blog/data-security-white-paper">
                Learn More
                <img className="arrow" src={arrow} width="14px" height="14px" />
              </Link>
            </CardActions>
          </Card>  
        </Box>
      </Box>
    </Box>
  );
};

export default Blog;
