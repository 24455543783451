import { Box, Link, TextField, Typography } from "@mui/material";
import "./WhyIngeniaCep.scss";
import whyCepIcon1 from "../../../statics/svgs/whyCepIcon1.svg";
import whyCepIcon2 from "../../../statics/svgs/whyCepIcon2.svg";
import whyCepIcon3 from "../../../statics/svgs/whyCepIcon3.svg";
import whyCepIcon4 from "../../../statics/svgs/whyCepIcon4.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const WhyIngeniaCep = () => {
  return (
    <Box className="whyIngeniaCep">
      <Box className="containerBox">
        <SectionHeading
          heading="Why Ingenia’s CEP?"
          subHeading="Most Innovative AI CRM Powering Unique Customer Experiences"
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyCepIcon1} alt="" />
            </Box>
            <Typography variant="h2">
              Tailor-made Interactive experiences
            </Typography>
            <Typography>
              Connect through fun, interactive, and enhanced experiences that
              resonates with the audiences.
            </Typography>
          </Box>
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyCepIcon2} alt="" />
            </Box>
            <Typography variant="h2">AI Loyalty program </Typography>
            <Typography>
              Deliver the right-sized reward tiers to more loyal customers.
            </Typography>
          </Box>
        </Box>
        <Box className="rowBox">
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyCepIcon3} alt="" />
            </Box>
            <Typography variant="h2">Analytics</Typography>
            <Typography>
              Use self-service analytics tools to determine what is working and
              what is not.
            </Typography>
          </Box>
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyCepIcon4} alt="" />
            </Box>
            <Typography variant="h2">Easy installation</Typography>
            <Typography>
              Schedule and easily execute store level promotions.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
