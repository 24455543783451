import { Typography, Box } from "@mui/material";
import "./letTalkGrid.scss";
interface Iprops {
  teamImg: string;
  teamName: string;
  founder?: string;
  founderCEO?: string;
  founderCOO?: string;
  vpTechnology?:string;
  CTO?: string;
  CEO?: string;
  CIO?: string;
  CIO2?: string;
  Lecturer?: string;
  Phd?: string;
  founderWilliams?: string;
  sales?: string;
  director?: string;
  bcom?: string;
  MBA?: string;
  Partner?: string;
  President?: string;
  Business?: string;
  VentureOperator?: string;
  ChiefProducts?: string;
  AssociateDirector?: string;
  ComputerEngineering?: string;
  VpTech?: string; 
  DirectorTechnology?: string;
  AsstManager?: string;
  BEIT?: string;
  VPProduct?: string;
  BusinessAnalyst?: string;
  Certifications?: string;
  
}

const LetTalkGrid = (props: Iprops) => {
  return (
    <Box className="wrapper1">
      <Box className="teamGrid">
        <Box className="container">
          <img src={props.teamImg}  height="400px" />
          <Typography className="teamName">{props.teamName}</Typography>

          <div className="overlay">
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "20px",
                textTransform: "uppercase",
                lineHeight: "32px",
                marginBottom:"7px",
              }}
            >
              {" "}
              {props.teamName}
            </Typography>

            <Typography style={{ fontSize: "12px" }}>{props.director && <span>Board Director:</span>} {props.director} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.founder && <span>Founder:</span>} {props.founder} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.CEO && <span>CEO:</span>} {props.CEO} </Typography>

            <Typography style={{ fontSize: "12px" }}>{props.founderCEO && <span>Founder & CEO:</span>} {props.founderCEO} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.CTO && <span>CTO:</span>} {props.CTO} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.CIO && <span>CIO:</span>} {props.CIO} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.CIO2 && <span>CIO:</span>} {props.CIO2} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.founderCOO && <span>Founder & COO:</span>} {props.founderCOO} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.vpTechnology && <span>VP Technology:</span>} {props.vpTechnology} </Typography>

            <Typography style={{ fontSize: "12px" }}>{props.Lecturer && <span>Lecturer:</span>} {props.Lecturer} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.Phd && <span>Phd:</span>} {props.Phd} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.sales && <span>VP Sales:</span>} {props.sales} </Typography>
            <Typography style={{ fontSize: "12px" }}>{props.bcom && <span>B.Com:</span>} {props.bcom} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.MBA && <span>MBA:</span>} {props.MBA} </Typography>  


            <Typography style={{ fontSize: "12px" }}>{props.Partner && <span>Founding Partner: </span>} {props.Partner} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.President && <span>President:</span>} {props.President} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.Business && <span>Business:</span>} {props.Business} </Typography>  

            <Typography style={{ fontSize: "12px" }}>{props.VentureOperator && <span>Venture Operator: </span>} {props.VentureOperator} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.ChiefProducts && <span>Chief Product & Marketing Officer:</span>} {props.ChiefProducts} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.AssociateDirector && <span>Associate Director:</span>} {props.AssociateDirector} </Typography> 
            <Typography style={{ fontSize: "12px" }}>{props.ComputerEngineering && <span>Computer Engineering:</span>} {props.ComputerEngineering} </Typography> 

            <Typography style={{ fontSize: "12px" }}>{props.VpTech && <span>VP, Technology: </span>} {props.VpTech} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.DirectorTechnology && <span>Director of Technology:</span>} {props.DirectorTechnology} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.AsstManager && <span>Asst. Manager (Mobile Team):</span>} {props.AsstManager} </Typography> 
            <Typography style={{ fontSize: "12px" }}>{props.BEIT && <span>B.E (IT):</span>} {props.BEIT} </Typography> 

            <Typography style={{ fontSize: "12px" }}>{props.VPProduct && <span>VP Product & Platform Delivery: </span>} {props.VPProduct} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.BusinessAnalyst && <span>Business Analyst:</span>} {props.BusinessAnalyst} </Typography>  
            <Typography style={{ fontSize: "12px" }}>{props.Certifications && <span>Certifications:</span>} {props.Certifications} </Typography>

          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default LetTalkGrid;
