import { Box, Link, TextField, Typography } from "@mui/material";
import "./ProfileBuilding.scss";
import profileBuilding from "../../../statics/svgs/ProfileBuilding.svg";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";

export const ProfileBuilding = () => {
  return (
    <Box className="profileBuilding">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="profileBuildingContent">
            <SectionHeading
              heading="Profile Building"
              alignItems="flex-start"
              marginBottom="10px"
            />
            <Typography>
            By Systematic monitoring of consumer behaviours and purchasing trends we employ this comprehensive information to construct robust customer profiles for you. 
            </Typography>
            <Typography>Using first-level Point-of-Sale (POS) & e-commerce, sales history and consumer interaction data, we ensure personalization as the baseline for all your campaigns.</Typography>
             
          </Box>
          <Box className="profileBuildingImage">
            <img src={profileBuilding} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
