import { Box, TextField,Typography } from "@mui/material";
import "./SectionHeading.scss";
import React from "react"

interface IProps {
    heading: string;
    subHeading?: string;
    marginBottom?:string;
    alignItems?:string;
  }
export const SectionHeading = (props: IProps) => {
    
  return (
    <Box className="sectionHeading" marginBottom={props.marginBottom} alignItems={props.alignItems}>
        <Typography variant="h2">{props.heading}</Typography>
        {props.subHeading && <Typography>{props.subHeading}</Typography>}
    </Box> 
  );
};
