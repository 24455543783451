import "./letTalk.scss";
import { Box, Typography } from "@mui/material";
import Image1 from "../../statics/svgs/pic/1.png";
import Image2 from "../../statics/svgs/pic/2.png";
import Image3 from "../../statics/svgs/pic/3.png";
import Image4 from "../../statics/svgs/pic/4.png";
import Image5 from "../../statics/svgs/pic/5.png";
import Image6 from "../../statics/svgs/pic/6.png";
import Image7 from "../../statics/svgs/pic/7.png";
import Image8 from "../../statics/svgs/pic/8.png";
import Strings from "../../core/utils/Strings";
import LetTalkGrid from "./LetTalkGrid/LetTalkGrid";
import { Link, animateScroll as scroll } from "react-scroll";
import scrollload from "../../statics/images/scrollload.gif";
import ClientLogo from "../common/clientLogo/ClientLogo";

const LetTalk = () => {
  return (
    <Box id="four" className="letTalkSection">
      <Box
        className="letTalkContainer"
        sx={{
          width: "100%",
          maxWidth: "1420px",
          padding: "0 40px",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            color: "#FFF",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                sm: "50px",
                xs: "45px",
              },
            }}
            className="bannerTitle"
          >
            {Strings.TEAM_SUB}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            margin: "0 auto",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            className="imgBox"
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "1380px",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap:"30px"
            }}
          >
            <LetTalkGrid
              founderCEO="PlumSlice Labs"
              CIO="B/E Aerospace"
              CIO2="Restoration Hardware"
              MBA="Columbia University"
              teamImg={Image4}
              teamName={Strings.TEAM_4}
            />
            <LetTalkGrid
              CEO="Photobucket "
              founderCEO="Milyoni"
              CIO="Mervyn’s"
              vpTechnology="Williams Sonoma"
              teamImg={Image3}
              teamName={Strings.TEAM_3}
            />
            <LetTalkGrid
              CEO="Integrated Compliance Solutions"
              director="Auka Capital"
              sales="Franklin Templeton"
              bcom="University of Alberta"
              teamImg={Image2}
              teamName={Strings.TEAM_2}
            />
            <LetTalkGrid
              VentureOperator="TCAP Private Equity"
              ChiefProducts="Telegence"
              AssociateDirector="Bell Canada"
              ComputerEngineering="Southern Alberta Institute of Technology
              "
              teamImg={Image6}
              teamName="Jeff Prete"
            />
            <LetTalkGrid
              VPProduct="Warpspd"
              BusinessAnalyst="PlumSlice Labs"
              Certifications="Wharton Online, AWS, 
            Indian Institute of Technology, Delhi
            International Software Testing Qualifications."
              teamImg={Image8}
              teamName="Shourya MAGOTRA"
            />
          </Box>
          {/* <Box
            className="imgBox"
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "1200px",
              margin: "30px auto 0",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            
            <LetTalkGrid
              founder="Parsl"
              CTO="YPB Connect"
              Lecturer="Monash University"
              Phd="Monash University Australia"
              teamImg={Image1}
              teamName={Strings.TEAM_1}
            />
            <LetTalkGrid
              Partner="Silverleaf Ventures"
              President="TriStar Advisors"
              Business="University of South Florida"
              teamImg={Image5}
              teamName="Doug Hannah"
            />
            
            <LetTalkGrid
              VpTech="Warpspd"
              DirectorTechnology="Firsthandle"
              AsstManager="PlumSlice Labs"
              BEIT="H.N.G.U University, Gujarat"
              teamImg={Image7}
              teamName="SAGAR PATEL"
            />
            
          </Box> */}
        </Box>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "26px",
            color: "#282461",
            letterSpacing: "-0.04em",
            margin: "5vh 0",
            textAlign: "center",
          }}
        >
          40+ full time associates
        </Typography>

        <ClientLogo />
      </Box>
    </Box>
  );
};
export default LetTalk;
