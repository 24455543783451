import { Box, Typography } from "@mui/material";
import "./ourTeam.scss";
import { Link, animateScroll as scroll } from "react-scroll";
import arrow from "../../statics/svgs/arrow.svg";
import logo from "../../statics/svgs/logo.svg";
import Strings from "../../core/utils/Strings";
import OurTeamGrid from "./OurTeamGrid/OurTeamGrid";
import scrollload from "../../statics/images/scrollload.gif";
import ContinuousInnovation from "../../statics/svgs/ContinuousInnovation.svg";
import DiverseInclusive from "../../statics/svgs/DiverseInclusive.svg";
import MeasurablePositive from "../../statics/svgs/MeasurablePositive.svg";
import Simplicity from "../../statics/svgs/Simplicity.svg";

const OurTeam = () => {
  return (
    <Box id="three" className="ourteamSection">
      <Box width="100%">
        <Box
          className="ourteamContainer"
          sx={{
            margin: "0 auto",
            color: "#FFF",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            width: "100%",
            maxWidth: "1420px",
            padding: "0 40px",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                sm: "50px",
                xs: "45px",
              },
            }}
            className="bannerTitle"
          >
            {Strings.TEAM_BANNER_TITLE}
          </Typography>
        </Box>

        <Box
          className="grid"
          sx={{
            display: "flex",
            margin: "0 auto",
            color: "#FFF",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "1420px",
            padding: "0 40px",
          }}
        >
          <Box
            className="ourTeamsBoxWarp"
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box className="ourTeamsBox" sx={{ width: "100%" }}>
              <OurTeamGrid
                width="calc(25% - 15px)"
                icon={DiverseInclusive}
                title="Diverse & Inclusive Entrepreneurs"
                content="Our entrepreneur partners are our greatest asset,we encourage all perspectives with a focus on performance and results"
              />
              <OurTeamGrid
                width="calc(25% - 15px)"
                icon={ContinuousInnovation}
                title="Continuous Innovation"
                content="From simple ideas to deep tech Al patents that help our
                  entrepreneurs compete and grow."
              />
              <OurTeamGrid
                width="calc(25% - 15px)"
                icon={MeasurablePositive}
                title="Measurable Positive Impact "
                content="Guides all our interactions and world class solutions for our entrepreneurs, their customers, employees, & communities."
              />
              <OurTeamGrid
                width="calc(25% - 15px)"
                icon={Simplicity}
                title="Simplicity"
                content="In everything we do, solving complex problems with simple approaches"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OurTeam;
