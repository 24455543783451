import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./ourTeamGrid.scss";
import "../../ourValue/OurValueGrid/ourValueGrid.scss";
interface Iprops {
  title: string;
  content: string;
  width: string;
  icon: string;
}
const OurTeamGrid = (props: Iprops) => {
  return (
    <Box className="valueGridBox" width={props.width}>
      <img src={props.icon} alt="icon" />
      <Typography variant="h3">{props.title}</Typography>
      <Typography>{props.content}</Typography>
    </Box>
  );
};

export default OurTeamGrid;
