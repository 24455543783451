import { Box, Link, TextField,Typography } from "@mui/material";
import "./CdpBanner.scss";
import arrow from "../../../statics/svgs/arrow.svg";
import inBannerImg from "../../../statics/svgs/inBannerImg.svg";
 
export const CdpBanner = () => { 
  return (
    <Box className="cdpSection">
        <Box className="containerBox">
          <Box className="cdpBanner">
          <Box className="bannerContent">
            <Typography variant="h2">
              Ingenia’s AI powered Patented<span>Consumer Data</span>
              <span>Platform (CDP)</span>
            </Typography>
            <Typography>
              Increase frequency of repeat purchases and average order value
              with Patented AI consumer data platform that understands and
              actionizes deep consumer behaviors & purchase habits.
            </Typography>
            <Link className="primaryBtn">
              <Typography>Learn More</Typography>{" "}
              <img className="arrow" src={arrow} width="18px" height="18px" />
            </Link>
          </Box>
          <Box className="inBannerImg">
            <img src={inBannerImg} alt="" />
          </Box>
          </Box>
        </Box>
      </Box>
  );
};
