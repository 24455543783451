import { Box, Link, TextField, Typography } from "@mui/material";
import "./ProvenResults.scss";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";  
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import provenResults1 from "../../../statics/svgs/provenResults1.svg";
import provenResults2 from "../../../statics/svgs/provenResults2.svg";
import provenResults3 from "../../../statics/svgs/provenResults3.svg";
import provenResults4 from "../../../statics/svgs/provenResults4.svg";
import provenResults5 from "../../../statics/svgs/provenResults5.svg";
import provenResults6 from "../../../statics/svgs/provenResults6.svg";
import provenResults7 from "../../../statics/svgs/provenResults7.svg";
import provenResults8 from "../../../statics/svgs/provenResults8.svg";
import provenResults9 from "../../../statics/svgs/provenResults9.svg";
import provenResults10 from "../../../statics/svgs/provenResults10.svg";
import provenResults11 from "../../../statics/svgs/provenResults11.svg";
import provenResults12 from "../../../statics/svgs/provenResults12.svg"; 


export const ProvenResults = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4, // Total items per page
    slidesToScroll: 4, // Scroll amount per page
    initialSlide: 0,
    nextArrow: <ArrowForwardIcon />,
    prevArrow: <ArrowBackIcon />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box className="provenResults">
      <Box className="containerBox">
        <SectionHeading
          heading="Proven Results"
          alignItems="center"
          marginBottom="40px"
        />
        <Box className="rowBox">
          <Slider {...settings} className="provenResultsOwl">
            <Box className="item"><img src={provenResults1} alt="" /></Box> 
            <Box className="item"><img src={provenResults2} alt="" /></Box> 
            <Box className="item"><img src={provenResults3} alt="" /></Box> 
            <Box className="item"><img src={provenResults4} alt="" /></Box> 
            <Box className="item"><img src={provenResults5} alt="" /></Box> 
            <Box className="item"><img src={provenResults6} alt="" /></Box> 
            <Box className="item"><img src={provenResults7} alt="" /></Box> 
            <Box className="item"><img src={provenResults8} alt="" /></Box>
            <Box className="item"><img src={provenResults9} alt="" /></Box>
            <Box className="item"><img src={provenResults10} alt="" /></Box>
            <Box className="item"><img src={provenResults11} alt="" /></Box>
            <Box className="item"><img src={provenResults12} alt="" /></Box>
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};
