import { Box, Link, TextField, Typography } from "@mui/material";
import "./CampaignRecommendation.scss";
import campaignRecommendation from "../../../statics/svgs/CampaignRecommendation.svg";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";

export const CampaignRecommendation = () => {
  return (
    <Box className="campaignRecommendation">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="campaignRecommendationImage">
            <img src={campaignRecommendation} alt="" />
          </Box>
          <Box className="campaignRecommendationContent">
            <SectionHeading
              heading="Campaign Recommendation"
              alignItems="flex-start"
              marginBottom="10px"
            />
            <Typography>
            Patented AI insights & algorithms analyze consumer behaviors & purchase habits including product choices, preferred brands, attribute preferences like colors, sizes, price, loyalty-tier and price points, etc. for autonomous generation of personalized recommendations, promotions, and campaigns to increase return visits, ecommerce, click-thrus, and conversion. 
            </Typography> 
            <Typography>We ensure goal-driven marketing.</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
