import { Box, Link, TextField, Typography } from "@mui/material";
import "./InventoryForecasting.scss";
import inventoryForecasting from "../../../statics/svgs/inventoryForecasting.svg"; 
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const InventoryForecasting = () => {
  return (
    <Box className="inventoryForecasting">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="inventoryForecastingImage">
            <img src={inventoryForecasting} alt="" />
          </Box>
          <Box className="inventoryForecastingContent">
            <SectionHeading
              heading="Inventory Forecasting"
              alignItems="flex-start"
              marginBottom="10px"
            />
            <Typography>
            Stay ahead of demand with our forecast-driven approach. Our system not only predicts future demand, but also aligns with open purchase orders (POs). This means you can replenish inventory proactively, minimizing stockouts and keeping your shelves stocked with the products your customers love. 
            </Typography>  
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
