import { Box, Link, TextField,Typography } from "@mui/material";
import "./ExtraRow.scss";
import ex1 from "../../../statics/svgs/ex1.svg";
import ex2 from "../../../statics/svgs/ex2.svg"; 
import ex3 from "../../../statics/svgs/ex3.svg"; 
 
export const ExtraRow = () => {
    
  return (
    <Box className="extraRow">
        <Box className="containerFluidBox">
          <Box className="rowBox">
            <Box className="exBox">
              <img src={ex1} alt="" /> 
            </Box>
            <Box className="exBox">
              <img src={ex2} alt="" /> 
            </Box> 
            <Box className="exBox">
              <img src={ex3} alt="" /> 
            </Box>
          </Box> 
        </Box>
      </Box>
  );
};
