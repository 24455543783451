import { Box, TextField,Typography } from "@mui/material";
import "./InputField.scss";
import React from "react"
export const InputField = (props: any) => {
  return (
    <Box className="inputGroup">
    {props?.inputLabel && (
      <Typography className="inputLabel">
        {props?.inputLabel}
        {props?.required && <span style={{ color: "#F10000" }}>{"  "}*</span>}
      </Typography>
    )}
    <TextField
      variant="standard" className="inputField" {...props}
      hiddenLabel 
    />
    </Box>

  );
};
