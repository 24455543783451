import { Box, Link, TextField, Typography } from "@mui/material";
import "./AIAlgorithms.scss";
import aIAlgorithms1 from "../../../statics/svgs/AIAlgorithms1.svg";
import aIAlgorithms2 from "../../../statics/svgs/AIAlgorithms2.svg";
import aIAlgorithms3 from "../../../statics/svgs/AIAlgorithms3.svg";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";

export const AIAlgorithms = () => {
  return (
    <Box className="aIAlgorithms">
      <Box className="containerBox">
        <SectionHeading
          heading="AI Algorithms Analyse Consumer’s Buying Behaviour & Predict Churn"
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="aIAlgorithmsImage">
            <img src={aIAlgorithms1} alt="" />
          </Box>
          <Box className="aIAlgorithmsImage">
            <img src={aIAlgorithms2} alt="" />
          </Box>
        </Box>
        <Box className="rowBox">
          <Box className="aIAlgorithmsImage fullImage">
            <img src={aIAlgorithms3} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
