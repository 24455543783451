import { Box, Link, TextField, Typography } from "@mui/material";
import "./HowItsWorksPdr.scss";
import howItsPdrIcon1 from "../../../statics/svgs/howItsPdrIcon1.svg";
import howItsPdrIcon2 from "../../../statics/svgs/howItsPdrIcon2.svg";
import howItsPdrIcon3 from "../../../statics/svgs/howItsPdrIcon3.svg"; 
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const HowItsWorksPdr = () => {
  return (
    <Box className="howItsWorksPdr">
      <Box className="containerBox">
      <SectionHeading
          heading="How it Works?"
          subHeading="Proactively, minimizing stock-outs and keeping your shelves stocked with the products your customers love!"
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="howItsWorkBox">
            <Box className="imgBox"><img src={howItsPdrIcon1} alt="" /></Box>
            <Typography variant="h2">Movement Score</Typography>
            <Typography>
            Understanding inventory movement trends to categorize products 
            </Typography>
          </Box>
          <Box className="howItsWorkBox">
            <Box className="imgBox"><img src={howItsPdrIcon2} alt="" /></Box> 
            <Typography variant="h2">AI Discount Recommendation</Typography>
            <Typography>
            Right discounting for each SKU without any guess works
            </Typography>
          </Box>
          <Box className="howItsWorkBox">
            <Box className="imgBox"><img src={howItsPdrIcon3} alt="" /></Box>  
            <Typography variant="h2">Inventory Forecasting  </Typography>
            <Typography>
            Precised Inventory Forecasting & insights for every SKU at various retail locations
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
