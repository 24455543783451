import { Box, Link, TextField, Typography } from "@mui/material";
import "./WhyIngenia.scss";
import whyIcon1 from "../../../statics/svgs/whyIcon1.svg";
import whyIcon2 from "../../../statics/svgs/whyIcon2.svg";
import whyIcon3 from "../../../statics/svgs/whyIcon3.svg";
import whyIcon4 from "../../../statics/svgs/whyIcon4.svg";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";

export const WhyIngenia = () => {
  return (
    <Box className="whyIngenia">
      <Box className="containerBox">
        <SectionHeading
          heading="Why Ingenia CDP?"
          subHeading="We understand that one size doesn’t fit all"
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="whyBox">
          <Box className="imgBox"><img src={whyIcon1} alt="" /></Box>
            <Typography variant="h2">
              Hyper-Local & Personalized Outreach
            </Typography>
            <Typography>
              Building highly personalized and localized outreach with
              auto-suggested content based on consumer preferences & trends.
            </Typography>
          </Box>
          <Box className="whyBox">
          <Box className="imgBox"><img src={whyIcon2} alt="" /></Box>
            <Typography variant="h2">Auto-piloted Targeting</Typography>
            <Typography>
              Auto-piloted targeting and optimized campaigns so you can allocate
              your efforts where truly needed.
            </Typography>
          </Box>
        </Box>
        <Box className="rowBox">
          <Box className="whyBox">
          <Box className="imgBox"><img src={whyIcon3} alt="" /></Box>
            <Typography variant="h2">Value-driven Growth</Typography>
            <Typography>
              Ensuring all your brand communications are consumer value-driven
              hence, leading to an assured increase in the retention & repeat
              sales.
            </Typography>
          </Box>
          <Box className="whyBox">
          <Box className="imgBox"><img src={whyIcon4} alt="" /></Box>
            <Typography variant="h2">Integrations</Typography>
            <Typography>
              Seamless CRM integration to manage customer data easily over 3rd
              party services.{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
