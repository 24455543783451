import "./ourValue.scss"
import { Box, Typography } from "@mui/material"
import activeIcon1 from "../../statics/images/activeIcon1.png"
import activeIcon2 from "../../statics/images/activeIcon2.png"
import activeIcon3 from "../../statics/images/activeIcon3.png"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import scrollload from "../../statics/images/scrollload.gif"
import { Link } from "react-scroll"

const OurValue = () => {
  return (
    <Box
      className="main"
      id="two"
      sx={{
        backgroundColor: "#E9E8FF", 
      }}
    >
      <Box
      className="ourValueContainer"
        sx={{
          width: "100%",
          margin: "0 auto",
          maxWidth: "1420px",
          padding: "0 40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Typography className="subHeading">
              Our Value Proposition
            </Typography>
          </Box>
        </Box>
        <Box className="valuePropostionBoxWarp" sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box className="valuePropostionBox">
            <img
              style={{ width: "100px", height: "100px" }}
              src={activeIcon1}
              alt="logo"
            />
            <Typography variant="h3">Solution Engineering</Typography>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>Product & competitor research</Typography>
            </Box>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>
                Productization using latest engineering technologies AI,
                Blockchain, Web3
              </Typography>
            </Box>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>IP protection</Typography>
            </Box>
          </Box>
          <Box className="valuePropostionBox">
            <img
              style={{ width: "100px", height: "100px" }}
              src={activeIcon2}
              alt="logo"
            />
            <Typography variant="h3">GTM Acceleration</Typography>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>Portfolio introductions</Typography>
            </Box>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>Segmentation, Targeting, Positioning</Typography>
            </Box>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>
                Fractional C-suite support with multiple startup exits &
                experience growing Fortune 500 companies
              </Typography>
            </Box>
          </Box>
          <Box className="valuePropostionBox">
            <img
              style={{ width: "100px", height: "100px" }}
              src={activeIcon3}
              alt="logo"
            />
            <Typography variant="h3">Investment Strategy</Typography>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>Direct investment</Typography>
            </Box>
            <Box className="valueRow">
              <CheckCircleIcon />
              <Typography>VC & investor introductions & support</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OurValue
