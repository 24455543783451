import { Box, Link, TextField, Typography } from "@mui/material";
import "./KeyFeatures.scss";   
import keyFeatures1 from "../../../statics/svgs/keyFeatures1.svg";
import keyFeatures2 from "../../../statics/svgs/keyFeatures2.svg";
import keyFeatures3 from "../../../statics/svgs/keyFeatures3.svg";
import keyFeatures4 from "../../../statics/svgs/keyFeatures4.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";
export const KeyFeatures = () => {
  return (
    <Box className="keyFeatures">
      <Box className="containerBox">
      <SectionHeading
          heading="Key Features" 
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox"> 
          <Box className="keyFeaturesBox">
            <img src={keyFeatures1} alt="" />
            <Typography variant="h2">Gamification</Typography>
          </Box> 
          <Box className="keyFeaturesBox">
            <img src={keyFeatures2} alt="" />
            <Typography variant="h2">Feedback</Typography>
          </Box>
          <Box className="keyFeaturesBox">
            <img src={keyFeatures3} alt="" />
            <Typography variant="h2">Promotions</Typography>
          </Box>
          <Box className="keyFeaturesBox">
            <img src={keyFeatures4} alt="" />
            <Typography variant="h2">Queue Management</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
