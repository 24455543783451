import { Box, Link, TextField, Typography } from "@mui/material";
import "./MovementScore.scss";
import movementScore from "../../../statics/svgs/movementScore.svg"; 
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const MovementScore = () => {
  return (
    <Box className="movementScore">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="movementScoreImage">
            <img src={movementScore} alt="" />
          </Box>
          <Box className="movementScoreContent">
            <SectionHeading
              heading="Movement score"
              alignItems="flex-start"
              marginBottom="10px"
            />
            <Typography>
            Product Movement Score, Expiration score for each SKU is calculated considering Multiple parameters like customer purchase patterns, seasonality, weather, region, etc. 
            </Typography>
            <Typography> These scores auto-identifty and categorize products into multiple groups, allowing you to strategize better for optimum sales.</Typography>
            <Box className="tagBox">
              <span>STAPLE</span>
              <span>BEST SELLERS</span>
              <span>SLOW MOVERS</span>
              <span>EXPIRING SOON</span>
              <span>NEW ARRIVAL</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
