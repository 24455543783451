import { Box, Typography } from "@mui/material";
import clientLogo1 from "../../../statics/images/client/client1.png";
import clientLogo2 from "../../../statics/images/client/client2.png";
import clientLogo3 from "../../../statics/images/client/client3.png";
import clientLogo4 from "../../../statics/images/client/client4.png";
import clientLogo5 from "../../../statics/images/client/client5.png";
import clientLogo6 from "../../../statics/images/client/client6.png";
import clientLogo7 from "../../../statics/images/client/client7.png";
import clientLogo8 from "../../../statics/images/client/client8.png";

const ClientLogo = () => {
  return (
    <Box
    
      sx={{
        display: "flex",
        margin: "0 auto",
        marginBottom: "25px", 
        color: "#FFF",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box className="clients"
        sx={{
          display: "flex",
          margin: "0 auto",
          color: "#FFF", 
          justifyContent: "space-between",
          width: "100%",
          alignItems:"center",
        }}
      >
        <img src={clientLogo5} width="100" />
        <img src={clientLogo6} width="126" />
        <img src={clientLogo1} width="166" />
        <img src={clientLogo7} width="160" />
        <img src={clientLogo8} width="60" />
        <img src={clientLogo2} width="147" />
        <img src={clientLogo4} width="115" /> 
      </Box>
    </Box>
  );
};

export default ClientLogo;
