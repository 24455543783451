import { Box, createTheme } from "@mui/material";
import "./InventorySegmentation.scss";
import inventorySegmentationM from "../../../statics/svgs/inventorySegmentationM.svg";
import inventorySegmentation from "../../../statics/svgs/inventorySegmentation.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";
import { useEffect, useState } from "react";
function getWindowSize() {
  const {innerWidth} = window;
  return {innerWidth};
}
export const InventorySegmentation = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  console.log("windowSize",windowSize)
  return (
    <Box className="inventorySegmentation">
      <Box className="containerBox">
        <SectionHeading
          heading="Inventory Segmentation and Micro Segmentation"
          subHeading="Advanced Segment recommendation build on top of cluster analysis using diverse inventory and transactional data feeds"
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          {windowSize.innerWidth > 768 ? (
            <img src={inventorySegmentation} alt="" />
          ) : (
            <img src={inventorySegmentationM} alt="" />
          )} 
        </Box>
      </Box>
    </Box>
    
  );
};
