import { useState } from "react";
import { Box, Modal } from "@mui/material";
import ContactUs from "../contactUs/ContactUs";
import { Header } from "../common/header/Header";
import "./Cep.scss";
import { CepBanner } from "./cepBanner/CepBanner";
import { WhyIngeniaCep } from "./whyIngeniaCep/WhyIngeniaCep"; 
import { Footer } from "../common/footer/Footer";
import { AboutIngeniaCep } from "./aboutIngeniaCep/AboutIngeniaCep";
import { Engage } from "./engage/Engage";
import { KeyFeatures } from "./keyFeatures/KeyFeatures";
import { EnablingMarketers } from "./enablingMarketers/EnablingMarketers";
import { Loyalty } from "./loyalty/Loyalty";
import { RewardPoints } from "./rewardPoints/RewardPoints";
import { WhatWeHaveDone } from "./whatWeHaveDone/WhatWeHaveDone";
import { PurchaseExperience } from "./purchaseExperience/PurchaseExperience";
import { ProvenResults } from "./provenResults/ProvenResults";
export const Cep = () => {
  const [openLetsConnect, setOpenLetsConnect] = useState(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    p: "0px",
  };
  const renderLetsConnectModel = () => {
    return (
      <Modal
        open={openLetsConnect}
        onClose={() => setOpenLetsConnect(false)}
        className="letsTalkPopup"
      >
        <Box sx={style}>
          <ContactUs
            pathName={"/cep"}
            setOpenLetsConnectFromCdp={setOpenLetsConnect}
          />
        </Box>
      </Modal>
    );
  };
  return (
    <Box className="cepPage">
      {openLetsConnect && renderLetsConnectModel()}
      <Header setOpenLetsConnect={setOpenLetsConnect} />
      <CepBanner />
      <WhyIngeniaCep />
      <Engage />
      <KeyFeatures />
      <EnablingMarketers />
      <Loyalty />
      <RewardPoints />
      <WhatWeHaveDone />
      <PurchaseExperience />
      <ProvenResults />
      <AboutIngeniaCep setOpenLetsConnect={setOpenLetsConnect} />
      <Footer />
    </Box>
  );
};
