import { useState } from "react";
import { Box, Modal } from "@mui/material";
import ContactUs from "../contactUs/ContactUs";
import { Header } from "../common/header/Header";
import "./Pdr.scss";
import { PdrBanner } from "./pdrBanner/PdrBanner";
import { WhyIngeniaPdr } from "./whyIngeniaPdr/WhyIngeniaPdr";
import { Footer } from "../common/footer/Footer";
import { AboutIngeniaPdr } from "./aboutIngeniaPdr/AboutIngeniaPdr";
import { HowItsWorksPdr } from "./howItsWorksPdr/HowItsWorksPdr";
import { InventorySegmentation } from "./inventorySegmentation/InventorySegmentation";
import { MovementScore } from "./movementScore/MovementScore";
import { AIDiscountRecommendation } from "./aIDiscountRecommendation/AIDiscountRecommendation";
import { OptimumBusiness } from "./optimumBusiness/OptimumBusiness";
import { InventoryForecasting } from "./inventoryForecasting/InventoryForecasting";
import { StoreWiseRecommendations } from "./storeWiseRecommendations/StoreWiseRecommendations";
export const Pdr = () => {
  const [openLetsConnect, setOpenLetsConnect] = useState(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    p: "0px",
  };
  const renderLetsConnectModel = () => {
    return (
      <Modal
        open={openLetsConnect}
        onClose={() => setOpenLetsConnect(false)}
        className="letsTalkPopup"
      >
        <Box sx={style}>
          <ContactUs
            pathName={"/pdr"}
            setOpenLetsConnectFromCdp={setOpenLetsConnect}
          />
        </Box>
      </Modal>
    );
  };

  return (
    <Box className="pdrPage">
      {openLetsConnect && renderLetsConnectModel()}
      <Header setOpenLetsConnect={setOpenLetsConnect} />
      <PdrBanner />
      <WhyIngeniaPdr />
      <InventorySegmentation />
      <HowItsWorksPdr />
      <MovementScore />
      <AIDiscountRecommendation />
      <OptimumBusiness />
      <InventoryForecasting />
      <StoreWiseRecommendations />
      <AboutIngeniaPdr setOpenLetsConnect={setOpenLetsConnect} />
      <Footer />
    </Box>
  );
};
