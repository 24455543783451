import "./Header.scss";
import { Box, Typography, Link } from "@mui/material";
import logo from "../../../statics/svgs/logo.svg";
import arrow from "../../../statics/svgs/arrow.svg";
// import { Link } from "react-scroll"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// interface IProps {
//   currentIndex: number
//   onChangeIndex(newIndex: number): void
// }
interface Iprops {
  setOpenLetsConnect?: any;
}
export const Header = (props: Iprops) => {
  const [offset, setOffset] = useState(-1);
  const [isLetsTalkEnable, setIsLetsTalkEnable] = useState(false);
  const screenHeight = window.innerHeight;
  const location = useLocation();

  useEffect(() => {
    setIsLetsTalkEnable(
      ["/cdp", "/pdr", "/cep"].includes(location.pathname.toLowerCase())
    );
    const onScroll = () =>
      setOffset(Math.trunc(window.pageYOffset / screenHeight) - 1);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handlechange = () => {
    setOffset(offset === 4 ? 0 : offset + 1);
  };

  const getButtonTitle = (index: number) => {
    var btnTitle = "";
    switch (index) {
      case 0:
        btnTitle = "Our Proposition";
        break;
      case 1:
        btnTitle = "Our Core Values";
        break;
      case 2:
        btnTitle = "Our Team";
        break;
      case 3:
        btnTitle = "Let’s talk";
        break;
      case 4:
        btnTitle = "Back to top";
        break;
      default:
        break;
    }
    return btnTitle;
  };

  const getToLink = (index: number) => {
    var toLink = "";
    switch (index) {
      case 0:
        toLink = "two";
        break;
      case 1:
        toLink = "three";
        break;
      case 2:
        toLink = "four";
        break;
      case 3:
        toLink = "five";
        break;
      case 4:
        toLink = "homeSection";
        break;
      default:
        break;
    }
    return toLink;
  };

  // const renderButton = (index: number) => {
  //   return null
  //   if (index == -1) {
  //     return null
  //   } else {
  //     return (
  //       <Link
  //         className="primaryBtn"
  //         to={getToLink(index)}
  //         onClick={handlechange}
  //         spy={true}
  //         smooth={true}
  //         offset={0}
  //         duration={500}
  //       >
  //         <Typography>{getButtonTitle(index)}</Typography>
  //         <img className="arrow" src={arrow} width="18px" height="18px" />
  //       </Link>
  //     )
  //   }
  // }

  return (
    <Box className={`header ${getToLink(offset)}Active`}>
      <Box
        className="headerContainer"
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "0 auto",
          color: "#FFF",
          padding: "1vh 40px",
          justifyContent: "space-between",
          maxWidth: "1420px",
          width: "100%",
        }}
      >
        <Box
          onClick={(e) => {
            document.getElementById("homeSection")!.scrollIntoView();
            setOffset(0);
          }}
        >
          <img style={{ width: "300px" }} src={logo} alt="logo" />
        </Box>
        {isLetsTalkEnable && (
          <Box color={"greenyellow"}>
            <Link
              className="primaryBtn"
              onClick={() => props?.setOpenLetsConnect(true)}
            >
              <Typography>Let’s talk</Typography>
              <img className="arrow" src={arrow} width="18px" height="18px" />
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};
