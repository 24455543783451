import { Box, Link, TextField, Typography } from "@mui/material";
import "./OptimumBusiness.scss";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const OptimumBusiness = () => {
  return (
    <Box className="optimumBusiness">
      <Box className="containerFluidBox">
        <Box className="rowBox">
          <SectionHeading
            heading="Optimum business performance with Advanced Algorithms    "
            subHeading="Our Proprietary Algorithms employ Reinforcement Learning ML techniques for Discounts, Stock-buy like business recommendations. 
            PDR provides unparallel results as compared to traditional systems."
            alignItems="center" 
          />
        </Box>
      </Box>
    </Box>
  );
};
