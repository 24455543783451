import { Box, Link, TextField, Typography } from "@mui/material";
import "./PdrBanner.scss";
import arrow from "../../../statics/svgs/arrow.svg";
import inBannerImgPdr from "../../../statics/svgs/inBannerImgPdr.png";

export const PdrBanner = () => {
  return (
    <Box className="pdrSection">
      <Box className="containerBox">
        <Box className="pdrBanner">
          <Box className="bannerContent">
            <Typography variant="h2">
              Ingenia’s <strong>product Discount</strong>
              <span>Recommender (PDR)</span>
            </Typography>
            <Typography>
              Proprietary AI/ML Algorithms Powering Dispensary/SKU level
              Discount Recommendations.
            </Typography>
            <Link className="primaryBtn">
              <Typography>Learn More</Typography>{" "}
              <img className="arrow" src={arrow} width="18px" height="18px" />
            </Link>
          </Box>
          <Box className="inBannerImg">
            <img src={inBannerImgPdr} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
