import { useEffect, useState } from 'react';
import './App.scss'
import { ThemeProvider } from "@mui/material/styles"
import Wrapper from './components/common/wrapper/Wrapper'
import Home from './components/home/Home'
import theme from "./theme"
import MouseComponent from './components/common/mouseComponent/MouseComponent';
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import DataSecurityWhitePaper from './components/dataSecurityWhitePaper/DataSecurityWhitePaper';
import Blog from './components/blog/Blog';
import { Cdp } from './components/cdp/Cdp';
import { Cep } from './components/cep/Cep';
import { Pdr } from './components/pdr/Pdr';

const App = () => {
  return (
    <>
    {/* <ThemeProvider theme={theme}>
      <Home id="homeSection"/>
      
    </ThemeProvider> */}
    <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home id="homeSection" />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/data-security-white-paper" element={<DataSecurityWhitePaper />} />  
          <Route path="/CDP" element={<Cdp />} />
          <Route path="/CEP" element={<Cep />} />
          <Route path="/PDR" element={<Pdr />} />
        </Routes>
      </ThemeProvider>
    </>
  )}
export default App