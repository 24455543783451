import { Box, Link, TextField, Typography } from "@mui/material";
import "./WhatWeHaveDone.scss";
import whatWeHaveDone1 from "../../../statics/svgs/whatWeHaveDone1.svg";
import whatWeHaveDone2 from "../../../statics/svgs/whatWeHaveDone2.svg";
import whatWeHaveDone3 from "../../../statics/svgs/whatWeHaveDone3.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const WhatWeHaveDone = () => {
  return (
    <Box className="whatWeHaveDone">
      <Box className="containerBox">
        <SectionHeading
          heading="What we have done for our customers"
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="whatWeHaveDoneBox">
            <Box className="iconBox">
              <img src={whatWeHaveDone1} alt="" />
            </Box>
            <Typography variant="h2">5% - 20%</Typography>
            <Typography>average Order Value increase</Typography>
          </Box>
          <Box className="whatWeHaveDoneBox">
            <Box className="iconBox">
              <img src={whatWeHaveDone2} alt="" />
            </Box>
            <Typography variant="h2">10% - 40%</Typography>
            <Typography>Revenue increase</Typography>
          </Box>
          <Box className="whatWeHaveDoneBox">
            <Box className="iconBox">
              <img src={whatWeHaveDone3} alt="" />
            </Box>
            <Typography variant="h2">5% - 10%</Typography>
            <Typography>Conversion increase</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
