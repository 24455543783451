import { Box, Typography } from "@mui/material";
import "./Loyalty.scss";
import loyalty from "../../../statics/svgs/loyalty.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";
export const Loyalty = () => {
  return (
    <Box className="loyalty">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="loyaltyContent">
            <SectionHeading
              heading="Loyalty"
              subHeading="Fostering long term customer relations"
              alignItems="flex-start" 
            />
            <Typography>
              Our Multi-tiered customer AI loyalty program uses consumer data,
              behavioral insights and gamification to improve lost-customer
              re-engagement, recommends highly personalized products and related
              Promotions & Reward to seamlessly increase loyalty.
            </Typography>
            <Typography>
              With AI insights on your dashboard, know more to offer better to
              different segments such as - Loyal, Loyal-at-risk,
              Potentially–loyal, and lost customers
            </Typography>
          </Box>
          <Box className="loyaltyImage">
            <img src={loyalty} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
