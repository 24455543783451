import { Box, Link, TextField, Typography } from "@mui/material";
import "./Engage.scss";
import engage from "../../../statics/svgs/engage.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const Engage = () => {
  return (
    <Box className="engage">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="engageImage">
            <img src={engage} alt="" />
          </Box>
          <Box className="engageContent">
            <SectionHeading
              heading="Engage"
              subHeading="Make shopping fun again!"
              alignItems="flex-start" 
            />
            <Typography>
              Powering interactive experiences on touchscreens in stores, on
              consumers phones via QR codes, and e-commerce sites that draw
              visitor attention making consumers engage with retailer/brand
              improving probability and value of sale.
            </Typography>
            <ul className="listBox">
              <li>
                Increase walk-ins and sales with our{" "}
                <strong>Gamification</strong> capabilities
              </li>
              <li>
                Create urgency and increase offer redemption rate with{" "}
                <strong>Deal of the Day</strong> incentives
              </li>
              <li>
                Incentivize purchases and collect validated consumer data via{" "}
                <strong>Contests</strong>
              </li>
              <li>
                Receive real-time customer comments using our mobile{" "}
                <strong>Feedback</strong> experience
              </li>
              <li>
                Eliminate physical lines by sending customers a text when their
                turn is up in line using our <strong>Virtual Queues</strong>{" "}
                solution
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
