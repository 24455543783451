import { Box, Link, TextField, Typography } from "@mui/material";
import "./AboutIngeniaCep.scss"; 
import arrow from "../../../statics/svgs/arrow.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";
interface Iprops {
  setOpenLetsConnect: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AboutIngeniaCep = (props: Iprops) => {
  return (
    <Box className="aboutIngeniaCep">
      <Box className="containerBox">
        <Box className="rowBox">
          <SectionHeading
            heading="Interested to Learn More About Ingenia’s CEP ?"
            alignItems="flex-start"
          />
          <Link
            className="primaryBtn"
            onClick={() => props.setOpenLetsConnect(true)}
          >
            <Typography>Let’s talk</Typography>
            <img className="arrow" src={arrow} width="18px" height="18px" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
