import { Box, Link, TextField, Typography } from "@mui/material";
import "./PurchaseExperience.scss";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";  
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import purchaseExperience1 from "../../../statics/svgs/purchaseExperience1.svg";
import purchaseExperience2 from "../../../statics/svgs/purchaseExperience2.svg";
import purchaseExperience3 from "../../../statics/svgs/purchaseExperience3.svg";
import purchaseExperience4 from "../../../statics/svgs/purchaseExperience4.svg";
import purchaseExperience5 from "../../../statics/svgs/purchaseExperience5.svg";
import purchaseExperience6 from "../../../statics/svgs/purchaseExperience6.svg";
import purchaseExperience7 from "../../../statics/svgs/purchaseExperience7.svg";
import purchaseExperience8 from "../../../statics/svgs/purchaseExperience8.svg";
import purchaseExperience9 from "../../../statics/svgs/purchaseExperience9.svg";
import purchaseExperience10 from "../../../statics/svgs/purchaseExperience10.svg";
import purchaseExperience11 from "../../../statics/svgs/purchaseExperience11.svg";
import purchaseExperience12 from "../../../statics/svgs/purchaseExperience12.svg"; 


export const PurchaseExperience = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4, // Total items per page
    slidesToScroll: 4, // Scroll amount per page
    initialSlide: 0,
    nextArrow: <ArrowForwardIcon />,
    prevArrow: <ArrowBackIcon />,

    responsive: [ 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box className="purchaseExperience">
      <Box className="containerBox">
        <SectionHeading
          heading="Delivering an innovative path-to-purchase experience"
          alignItems="center"
          marginBottom="40px"
        />
        <Box className="rowBox">
          <Slider {...settings} className="purchaseExperienceOwl">
            <Box className="item"><img src={purchaseExperience1} alt="" /></Box> 
            <Box className="item"><img src={purchaseExperience2} alt="" /></Box> 
            <Box className="item"><img src={purchaseExperience3} alt="" /></Box> 
            <Box className="item"><img src={purchaseExperience4} alt="" /></Box> 
            <Box className="item"><img src={purchaseExperience5} alt="" /></Box> 
            <Box className="item"><img src={purchaseExperience6} alt="" /></Box> 
            <Box className="item"><img src={purchaseExperience7} alt="" /></Box> 
            <Box className="item"><img src={purchaseExperience8} alt="" /></Box>
            <Box className="item"><img src={purchaseExperience9} alt="" /></Box>
            <Box className="item"><img src={purchaseExperience10} alt="" /></Box>
            <Box className="item"><img src={purchaseExperience11} alt="" /></Box>
            <Box className="item"><img src={purchaseExperience12} alt="" /></Box>
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};
