import { Box } from "@mui/material";
import "./StoreWiseRecommendations.scss"; 
import storeWiseRecommendations from "../../../statics/svgs/storeWiseRecommendations.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading"; 
export const StoreWiseRecommendations = () => {
  return (
    <Box className="storeWiseRecommendations">
      <Box className="containerBox">
      <SectionHeading
          heading="Store Wise Recommendations"
          subHeading="Recommendations and Actionable insights for every store that can revolutionize the way you manage and sell products by seamlessly tracking inventory movement, analyzing point of sale/e-commerce data, customer data."
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox"> 
           <img src={storeWiseRecommendations} alt="" />
        </Box>
      </Box>
    </Box>
  );
};
