import { Box, Link, TextField, Typography } from "@mui/material";
import "./StatisticsData.scss";

export const StatisticsData = () => {
  return (
    <Box className="statisticsData">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="statisticsDataBox">
            <Box className="centerBox">
              <Typography variant="h2">76%</Typography>
              <Typography>
                Of consumers expect companies to understand their needs and
                expectations
              </Typography>
              <Typography variant="h3">
                Source: <span>Salesforce</span>
              </Typography>
            </Box>
          </Box>
          <Box className="statisticsDataBox bg2">
            <Box className="centerBox">
              <Typography variant="h2">80%</Typography>
              <Typography>
                Organizations that lead in customer experience outperformed
                laggards on the S&P 500 index.
              </Typography>
              <Typography variant="h3">
                Source: <span>Qualtrics</span>
              </Typography>
            </Box>
          </Box>
          <Box className="statisticsDataBox bg3">
            <Box className="centerBox">
              <Typography variant="h2">760%</Typography>
              <Typography>
              Average increase in revenue generate have been shown by Segmented marketing campaigns
              </Typography>
              <Typography variant="h3">
                Source: <span> DMA’s national client email</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
