import { Box, Link, TextField, Typography } from "@mui/material";
import "./AIDiscountRecommendation.scss";
import aIDiscountRecommendation from "../../../statics/svgs/aIDiscountRecommendation.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const AIDiscountRecommendation = () => {
  return (
    <Box className="aIDiscountRecommendation">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="aIDiscountRecommendationContent">
            <SectionHeading
              heading="AI Discount Recommendation"
              alignItems="flex-start"
              marginBottom="10px"
            />
            <Typography>
              Ingenia’s AI Product Discount recommender generates precise
              discount recommendations at SKU-level for defined business goals.
            </Typography>
            <Typography>
              Provides actionable insights and even take pre-set actions
              considering several criteria including:
            </Typography>
            <ul className="listBox">
              <li>Sell through trends</li>
              <li>SKU/Lot expiration cycle times</li>
              <li>Inventory movement velocity (coming in and going out)</li>
              <li>Current inventory level</li>
              <li>Inventory trends</li>
              <li>Quantities </li>
              <li>Lead times & Down times </li>
            </ul>
          </Box>
          <Box className="aIDiscountRecommendationImage">
            <img src={aIDiscountRecommendation} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
