import { Box, Link, TextField, Typography } from "@mui/material";
import "./AboutIngenia.scss";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";
import arrow from "../../../statics/svgs/arrow.svg";
interface Iprops {
  setOpenLetsConnect: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AboutIngenia = (props: Iprops) => {
  return (
    <Box className="aboutIngenia">
      <Box className="containerBox">
        <Box className="rowBox">
          <SectionHeading
            heading="Interested to Learn More About Ingenia’s CDP?"
            alignItems="flex-start"
          />
          <Link
            className="primaryBtn"
            onClick={() => props.setOpenLetsConnect(true)}
          >
            <Typography>Let’s talk</Typography>
            <img className="arrow" src={arrow} width="18px" height="18px" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
