import { Box, Link, TextField, Typography } from "@mui/material";
import "./AIPowered.scss";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";
import aIPowered1 from "../../../statics/svgs/aIPowered1.svg";
import aIPowered2 from "../../../statics/svgs/aIPowered2.svg";
import aIPowered3 from "../../../statics/svgs/aIPowered3.svg";

export const AIPowered = () => {
  return (
    <Box className="aIPowered">
      <Box className="containerBox">
        <SectionHeading
          heading="AI Powered Highly Personalized Consumer Campaigns "
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="aIPoweredBox">
            <Box className="centerBox">
              <Typography variant="h2">AI Suggested </Typography>
              <Typography><span></span>Loyal Customers </Typography>
              <Typography><span></span>Browsers</Typography>
              <Typography><span></span>Loyal at Risk </Typography>
              <Typography><span></span>At Risk </Typography>
              <Typography><span></span>High Value </Typography>
              <Typography><span></span>High Value at Risk </Typography>
              <Typography><span></span>Lost Customers </Typography> 
            </Box>
            <Box className="iconBox">
              <img src={aIPowered1} alt="" />
            </Box>
          </Box>
          <Box className="aIPoweredBox">
            <Box className="centerBox">
              <Typography variant="h2">Auto - Event Driven </Typography>
              <Typography><span></span>Birthday </Typography>
              <Typography><span></span>Anniversary</Typography>
              <Typography><span></span>First Purchase</Typography>
              <Typography><span></span>After Purchase </Typography>
              <Typography><span></span>Daily Deals</Typography>
              <Typography><span></span>Location </Typography>
              <Typography><span></span>Lifetime Value</Typography> 
              <Typography><span></span>Brand Purchased</Typography> 
            </Box>
            <Box className="iconBox">
              <img src={aIPowered2} alt="" />
            </Box>
          </Box>
          <Box className="aIPoweredBox">
            <Box className="centerBox">
              <Typography variant="h2">Manual</Typography>
              <Typography><span></span>Customer List </Typography>
              <Typography><span></span>Any Customer Segment</Typography>
              <Typography><span></span>Purchase Store</Typography>
              <Typography><span></span>Holiday Type </Typography>
              <Typography><span></span>Daily Deals</Typography>
              <Typography><span></span>Location </Typography> 
            </Box>
            <Box className="iconBox">
              <img src={aIPowered3} alt="" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
