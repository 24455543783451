import { Box, Typography } from "@mui/material";
import React from "react";
import { Header } from "../common/header/Header";
import "./DataSecurityWhitePaper.scss";

const DataSecurityWhitePaper = () => {
  return (
    <Box className="blogDetailsPage">
      <Header />
      <Box className="blogDetails">
        <Typography variant="h1">Data Security White Paper</Typography>
        <Typography variant="h2">Introduction</Typography>
        <Typography>
          In today's digital landscape, organizations like Ingenia are at the
          forefront of leveraging technology to drive innovation and growth.
          With increased reliance on information technology (IT) assets, the
          need for robust data security measures becomes paramount. This white
          paper outlines Ingenia's approach to data security, its significance,
          methodologies, and technologies employed to protect sensitive
          information.
        </Typography>
        <Typography variant="h3">
          Understanding Data Security at Ingenia
        </Typography>
        <Typography>
          Data security is a core principle at Ingenia, aimed at safeguarding
          critical business assets and user information. It involves the
          implementation of strategies, processes, and technologies that ensure
          the confidentiality, integrity, and availability of data.
        </Typography>
        <Typography variant="h3">Defining Data Security</Typography>
        <Typography>
          Data security refers to the comprehensive set of practices implemented
          by Ingenia to protect data from unauthorized access, breaches,
          corruption, and misuse. It encompasses various techniques, such as
          encryption, tokenization, and key management, tailored to different
          types of data and regulatory compliance requirements.
        </Typography>
        <Typography variant="h3">Key Components of Data Security</Typography>
        <Typography>
          Ingenia's data security approach revolves around the following
          components:
        </Typography>
        <ol>
          <li>
            Confidentiality: Ensuring that sensitive data remains accessible
            only to authorized individuals or systems.
          </li>
          <li>
            Integrity: Guaranteeing the accuracy and reliability of data
            throughout its lifecycle.
          </li>
          <li>
            Availability: Ensuring data is accessible when needed without
            interruption.
          </li>
        </ol>
        <Typography variant="h3">
          Importance of Data Security for Ingenia
        </Typography>
        <Typography variant="h3">
          Data security is pivotal for Ingenia for several reasons:
        </Typography>
        <ol>
          <li>
            Protecting Intellectual Property: Ingenia's innovative products and
            solutions are the result of substantial intellectual capital. Data
            security safeguards this intellectual property from theft or
            compromise.
          </li>
          <li>
            Ensuring Client Trust: Clients entrust Ingenia with their data.
            Robust security measures build and maintain this trust by preventing
            breaches and unauthorized access.
          </li>
          <li>
            Regulatory Compliance: Ingenia adheres to strict industry
            regulations and compliance standards. Data security ensures
            compliance and minimizes legal risks.
          </li>
          <li>
            Business Continuity: Data security measures, such as regular
            backups, guarantee business continuity even in the face of
            unexpected disruptions.
          </li>
        </ol>
        <Typography variant="h3">
          Data Security Technologies at Ingenia
        </Typography>
        <Typography>
          Ingenia employs cutting-edge data security technologies to fortify its
          defense against threats:
        </Typography>
        <ol>
          <li>
            Data Encryption: Sensitive data is encrypted using advanced
            encryption algorithms. This ensures that even if unauthorized access
            occurs, the data remains unintelligible and unusable.
          </li>
          <li>
            Data Masking: Ingenia uses data masking to protect sensitive
            information from being exposed to unauthorized personnel. This adds
            an extra layer of security, especially during internal data
            handling.
          </li>
          <li>
            Data Erasure: When data is no longer required, secure erasure
            methods are employed to ensure complete removal from systems,
            minimizing the risk of data remnants being exploited.
          </li>
          <li>
            Data Resilience: Regular backups and redundancy mechanisms ensure
            that in case of data loss due to accidents or breaches, the
            organization can swiftly restore normal operations.
          </li>
        </ol>
        <Typography variant="h3">Conclusion</Typography>
        <Typography>
          Ingenia's commitment to data security underscores its dedication to
          excellence, trust, and compliance. By implementing robust strategies,
          leveraging advanced technologies, and fostering a culture of awareness
          and responsibility, Ingenia ensures that its data assets remain
          impervious to threats. This approach not only safeguards Ingenia's
          reputation but also ensures that its clients can confidently partner
          in its innovative journey, knowing their data is in secure hands.
        </Typography>
      </Box>
    </Box>
  );
};

export default DataSecurityWhitePaper;
