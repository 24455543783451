import { Box, Link, TextField, Typography } from "@mui/material";
import "./WhyIngeniaPdr.scss";
import whyPdrIcon1 from "../../../statics/svgs/whyPdrIcon1.svg";
import whyPdrIcon2 from "../../../statics/svgs/whyPdrIcon2.svg";
import whyPdrIcon3 from "../../../statics/svgs/whyPdrIcon3.svg";
import whyPdrIcon4 from "../../../statics/svgs/whyPdrIcon4.svg";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const WhyIngeniaPdr = () => {
  return (
    <Box className="whyIngeniaPdr">
      <Box className="containerBox">
        <SectionHeading
          heading="Why Ingenia PDR?"
          subHeading="Unlock the potential of your inventory with Ingenia’s Product Discount Recommender. Embrace the future of retail optimization! "
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyPdrIcon1} alt="" />
            </Box>
            <Typography variant="h2">
              Tailor-made Interactive experiences
            </Typography>
            <Typography>
              Connect through fun, interactive, and enhanced experiences that
              resonates with the audiences.
            </Typography>
          </Box>
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyPdrIcon2} alt="" />
            </Box>
            <Typography variant="h2">AI Loyalty program </Typography>
            <Typography>
              Deliver the right-sized reward tiers to more loyal customers.
            </Typography>
          </Box>
        </Box>
        <Box className="rowBox">
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyPdrIcon3} alt="" />
            </Box>
            <Typography variant="h2">Analytics</Typography>
            <Typography>
              Use self-service analytics tools to determine what is working and
              what is not.
            </Typography>
          </Box>
          <Box className="whyBox">
            <Box className="imgBox">
              <img src={whyPdrIcon4} alt="" />
            </Box>
            <Typography variant="h2">Easy installation</Typography>
            <Typography>
              Schedule and easily execute store level promotions.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
