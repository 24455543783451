import "./proposition.scss";
import { Box, Typography } from "@mui/material";
import Strings from "../../core/utils/Strings";
import Proposition_banner from "../../statics/svgs/Proposition_banner.svg";
import PropositionGrid from "./propositionGrid/PropositionGrid";
import { Link, animateScroll as scroll } from "react-scroll";
import scrollload from "../../statics/images/scrollload.gif";

const Proposition = () => {
  return (
    <>
      <Box id="one" className="propositionSection">
        <Box className="propositionSectionContainer" width="100%" maxWidth="1420px" padding="0 40px" margin="0 auto">
        <Box
          sx={{
            margin: "0 auto",
            color: "#FFF",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            maxWidth:"100%", 
            width:"100%", 
          }}
        >
          <Typography
            sx={{
              fontSize: {
                sm: "44px",
                xs: "32px",
              },
              textAlign: {
                sm: "center",
                xs: "left",
              },
            }}
            className="bannerTitle"
          >
            {Strings.PROPOSITION_BANNER_TITLE}
          </Typography>
          <Box
            className="propositionBanner" 
          >
            <img alt="propositionBanner" src={Proposition_banner} />
          </Box>
        </Box>

        <Box
          className="propositionGrid"
          sx={{
            display: "flex",
            margin: "0 auto",
            color: "#FFF",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "space-between",
            width: "100%", 
          }}
        >
          <Typography className="proposSubTitle">We handle the technology, while our partners drive market efforts through their established presence

</Typography>
          {/* <PropositionGrid
            gridWidth="100%"
            subHead="01./Technology"
            subPrea="Innovative productization of advanced technologies including"
            blockchain="AI, blockchain and more."
          />
          <PropositionGrid
            gridWidth="100%"
            subHead="02./Strategy"
            subPrea="Leadership and mentorship with multiple startup exits and experience in growing"
            blockchain="Fortune 500"
            compaines="companies"
          />
          <PropositionGrid
            gridWidth="100%"
            subHead="03./Execution"
            subPrea="Multi-dimensional support including"
            blockchain="Go-To-Market, Financing, and IP Protection."
          /> */}
        </Box>
        {/* <Box className="animationBtn"
          sx={{
            display: "flex", 
            justifyContent: "center",
          }}
         >
          <Link
            style={{ cursor: "pointer" }}
            to="two"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <img
              className="scrollload"
              src={scrollload}
              width="32px"
              height="57px"
            />
          </Link>
        </Box> */}
        </Box>
      </Box>
    </>
  );
};
export default Proposition;
