
import { Link } from "react-scroll";
import { Box } from "@mui/material";
import scrollload from "../../..//statics/images/scrollload.gif";
import { useEffect, useState } from "react";
import "./MouseComponent.scss"
const MouseComponent = () => {


    const [offset, setOffset] = useState(0);
    const screenHeight = window.innerHeight;

    useEffect(() => {
        const onScroll = () => setOffset(Math.trunc(window.pageYOffset / screenHeight));
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    console.log("asasas", offset)
    const handlechange = () => {
        // setOffset(offset === 4 ? 0 : offset + 1)
        // if (offset == 5) {
            setOffset(offset === 5 ? 0 : offset + 1)
        // }
        // setOffset(offset + 1)
    }


    const getToLink = (index: number) => {
        var toLink = ""
        switch (index) {
            case 0:
                toLink = "one"
                break
            case 1:
                toLink = "two"
                break
            case 2:
                toLink = "three"
                break
            case 3:
                toLink = "four"
                break
            case 4:
                toLink = "five"
                break
            default:
                break
        }
        return toLink
    }

    return (
        <>
            {offset < 5 &&
                <Box
                    className="animationBtn"
                    sx={{
                        display: "flex",

                        justifyContent: "center",
                    }}
                >
                    <Link
                        style={{ cursor: "pointer" }}
                        to={getToLink(offset)}
                        onClick={handlechange}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >
                        <img
                            className="scrollload"
                            src={scrollload}
                            width="32px"
                            height="57px"
                        />
                    </Link>
                </Box>
            }
        </>
    )
}
export default MouseComponent