import { Box, Modal } from "@mui/material";
import { Header } from "../common/header/Header";
import "./Cdp.scss";
import { CdpBanner } from "./cdpBanner/CdpBanner";
import { WhyIngenia } from "./whyIngenia/WhyIngenia";
import { ExtraRow } from "./extraRow/ExtraRow";
import { HowItsWorks } from "./howItsWorks/HowItsWorks";
import { MicroSegmentation } from "./microSegmentation/MicroSegmentation";
import { CampaignRecommendation } from "./campaignRecommendation/CampaignRecommendation";
import { ProfileBuilding } from "./profileBuilding/ProfileBuilding";
import { AIAlgorithms } from "./aIAlgorithms/AIAlgorithms";
import { StatisticsData } from "./statisticsData/StatisticsData";
import { AIPowered } from "./aIPowered/AIPowered";
import { AboutIngenia } from "./aboutIngenia/AboutIngenia";
import { Footer } from "../common/footer/Footer";
import { useState } from "react";
import ContactUs from "../contactUs/ContactUs";
export const Cdp = () => {
  const [openLetsConnect, setOpenLetsConnect] = useState(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    p: "0px",
  };
  const renderLetsConnectModel = () => {
    return (
      <Modal
        open={openLetsConnect}
        onClose={() => setOpenLetsConnect(false)}
        className="letsTalkPopup"
      >
        <Box sx={style}>
          <ContactUs
            pathName={"/cdp"}
            setOpenLetsConnectFromCdp={setOpenLetsConnect}
          />
        </Box>
      </Modal>
    );
  };
  return (
    <Box className="cdpPage">
      {openLetsConnect && renderLetsConnectModel()}
      <Header setOpenLetsConnect={setOpenLetsConnect}/>
      <CdpBanner />
      <WhyIngenia />
      <ExtraRow />
      <HowItsWorks />
      <MicroSegmentation />
      <CampaignRecommendation />
      <ProfileBuilding />
      <AIAlgorithms />
      <StatisticsData />
      <AIPowered />
      <AboutIngenia setOpenLetsConnect={setOpenLetsConnect} />
      <Footer />
    </Box>
  );
};
