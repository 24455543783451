import { Box, Link, TextField, Typography } from "@mui/material";
import "./CepBanner.scss";
import arrow from "../../../statics/svgs/arrow.svg";
import inBannerImgCep from "../../../statics/svgs/inBannerImgCep.png";

export const CepBanner = () => {
  return (
    <Box className="cepSection">
      <Box className="containerBox">
        <Box className="cepBanner">
          <Box className="bannerContent">
            <Typography variant="h2">
              Ingenia’s <strong>Consumer</strong>
              <span>Engagement Platform (CEP)</span>
            </Typography>
            <Typography>
              Deliver engaging experiences that drive traffic, conversion, and
              loyalty.
            </Typography>
            <Link className="primaryBtn">
              <Typography>Learn More</Typography>{" "}
              <img className="arrow" src={arrow} width="18px" height="18px" />
            </Link>
          </Box>
          <Box className="inBannerImg">
            <img src={inBannerImgCep} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
