import "./home.scss";
import { Link, animateScroll as scroll } from "react-scroll";
import { Box, Typography } from "@mui/material";
import logo from "../../statics/svgs/logo.svg";
import bannerImg from "../../statics/svgs/bannerImg.svg";
import arrow from "../../statics/svgs/arrow.svg";
import scrollload from "../../statics/images/scrollload.gif";
import Strings from "../../core/utils/Strings";
import MouseComponent from "../common/mouseComponent/MouseComponent";
import Wrapper from "../common/wrapper/Wrapper";

interface IProps {
  id: string;
}

const Home = (props: IProps) => {
  return (
    <>
      <Box id="homeSection" className="homeSection">
        <Box
          className="containerHome"
          sx={{
            display: "flex",
            minHeight: "100vh",
            alignItems: "center",
            margin: "0 auto",
            padding: "0 0 0 80px",
            color: "#FFF",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "1480px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              color: "#FFF",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img
              style={{ width: "38vw" }}
              src={logo}
              className="logoImg"
              alt="logo"
            />
            <Typography
              sx={{
                marginTop: "30px",
                fontSize: {
                  sm: "50px",
                  xs: "45px",
                },
              }}
              className="bannerTitle"
            >
              {Strings.HOME_BANNER_TITLE}
            </Typography>

            <Box sx={{ marginTop: "50px" }}>
              <Link
                className="primaryBtn"
                style={{ color: "red" }}
                to="propositionSection"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <Typography>Learn More</Typography>{" "}
                <img className="arrow" src={arrow} width="18px" height="18px" />
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              color: "#FFF",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",

              width: {
                xs: "100%",
                sm: "100%",
              },
            }}
            className="bnrImg"
          >
            <img src={bannerImg} />
          </Box>
        </Box>
        {/* <MouseComponent /> */}
      </Box>
      <Wrapper />
      <MouseComponent />
    </>
  );
};

export default Home;
