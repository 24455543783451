import { Box, Link, TextField, Typography } from "@mui/material";
import "./MicroSegmentation.scss";
import microSegmentation from "../../../statics/svgs/MicroSegmentation.svg";
import { SectionHeading } from "../commonItems/sectionHeading/SectionHeading";

export const MicroSegmentation = () => {
  return (
    <Box className="microSegmentation">
      <Box className="containerBox">
        <Box className="rowBox">
          <Box className="microSegmentationContent">
            <SectionHeading
              heading="Micro-Segmentation"
              alignItems="flex-start"
              marginBottom="10px"
            />
            <Typography>
              AI-powered segmentation engine that automatically filters the
              targeted market micro-segments for given product category.
              Specific goal-oriented targeting is ensured through Hyper-local
              and hyper-personalized out-reach.
            </Typography>
            <Typography variant="h3">Example - Is This Customer?</Typography>
            <Box className="tagBox">
              <span>At Risk</span>
              <span>A Trend Setter or A Trend Follower</span>
              <span>A Full Price Shopper or Waits For Discounts</span>
              <span>A Loyal Customer</span>
              <span>A High Value Customer</span>
            </Box>
          </Box>
          <Box className="microSegmentationImage">
            <img src={microSegmentation} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
