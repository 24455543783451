import { Box, Link, TextField, Typography } from "@mui/material";
import "./EnablingMarketers.scss";
import { SectionHeading } from "../../cdp/commonItems/sectionHeading/SectionHeading";

export const EnablingMarketers = () => {
  return (
    <Box className="enablingMarketers">
      <Box className="containerBox">
        <SectionHeading
          heading="Enabling Marketers to foster long-term customer relations " 
          alignItems="center"
          marginBottom="50px"
        />
        <Box className="rowBox">
          <Box className="enablingMarketersBox">
            <Typography variant="h2">Converted Window Shoppers</Typography>
            <Box className="iconBox">26%</Box>
            <Typography>...of those who did not make a purchase and were targeted returned and purchased</Typography>
          </Box>
          <Box className="enablingMarketersBox">
            <Typography variant="h2">Lost-Customer Re-engagement</Typography>
            <Box className="iconBox">9%</Box>
            <Typography>...of lost customers from last 3 years who were targeted bought again</Typography>
          </Box>
          <Box className="enablingMarketersBox">
            <Typography variant="h2">Increase In Overall Sales</Typography>
            <Box className="iconBox">6%</Box>
            <Typography>...average increase in overall stores sales</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
